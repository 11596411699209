import cx from 'classnames'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import ErrorBag from '../errorBag/ErrorBag'
import styles from './checkbox.module.scss'
import { isTestEnv } from '../../../../utils/functions/isTestEnv'
const isTest = isTestEnv()

/**
 * Checkbox Component.
 *
 * @param {Object} { name, checkedOptions, className, validation, onChange, type, label } as props
 * @returns {JSX.Element}
 */
const Checkbox = ({
  className = '',
  labelClassName = 'mt-1',
  itemsHeightControlClassNames = '',
  onChange = () => null,
  dataCy = '',
  ...props
}: CheckboxProps) => {
  const wasChanged = useRef(false)
  const handleCheckedOptionsChange = useCallback(() => {
    const options = {} as Record<string, boolean>
    if (props?.options && props?.options?.length) {
      props.options.forEach((option) => {
        options[option.value] =
          !!props?.checkedOptions && props.checkedOptions.includes(option.value)
      })
    }
    return options
  }, [props.checkedOptions, props.options])
  const [localCheckedOptions, setLocalCheckedOptions] = useState(handleCheckedOptionsChange())

  const [errorMessages, setErrorMessages] = useState<Record<string, any>>([])
  useEffect(() => {
    props?.errorMessage && setErrorMessages(props?.errorMessage)
  }, [props.errorMessage])

  useEffect(() => {
    if (props?.reset) {
      setLocalCheckedOptions({})
    }
  }, [props.reset])

  const handleOnchange = (event: any) => {
    const option = event.target.value
    const value = !localCheckedOptions[option]
    setLocalCheckedOptions((localCheckedOptions: any) => ({
      ...localCheckedOptions,
      [option]: value
    }))

    wasChanged.current = true

    if (props?.validation) {
      const messages = props.validation(value.toString(), option) || []
      setErrorMessages((errorMessages: any) => ({ ...errorMessages, ...(messages as any)[0] }))
    }
    onChange && onChange(event)
  }

  useEffect(() => {
    if (wasChanged.current) {
      onChange(localCheckedOptions)
    }
    wasChanged.current = false
  }, [localCheckedOptions, onChange])

  return (
    // Pass the itemsHeightControlClassNames to control the height and overflow of the container example 'max-h-96 overflow-auto'
    <div className={cx(className, itemsHeightControlClassNames)}>
      {props?.header && <h3 className={cx(props?.headerClassName ?? '')}>{props?.header ?? ''}</h3>}
      {props?.options &&
        !!props?.options?.length &&
        props?.options?.map((option, index) => {
          /** For Terms and conditions checkbox, we want to change the color of the label instead of showing error message */
          let hasTcError = false
          if (option?.value === 'tc') {
            hasTcError = errorMessages?.tc
          }

          return (
            <span
              onFocusCapture={() => {
                props?.onFocus && props.onFocus()
              }}
              tabIndex={0}
              key={index}
              className={cx(labelClassName, {
                [cx(styles['drive-form-checkbox__label-page'])]: props?.isPage
              })}
            >
              <div
                className={cx('seperator-full', styles['drive-form-checkbox__separator'], {
                  hidden: !props?.seperatorIndex || props?.seperatorIndex !== index,
                  inline: props?.seperatorIndex && !(props?.seperatorIndex === index)
                })}
              />
              {/* FIX: Global CSS refactor */}
              <label className={cx('label', labelClassName)}>
                <span className={cx(styles['drive-form-checkbox__input-wrapper'])}>
                  <input
                    type='checkbox'
                    className='hidden'
                    name={option?.name}
                    value={option?.value}
                    checked={!!localCheckedOptions[option?.value]}
                    onChange={(e) => handleOnchange(e)}
                    data-testid={isTest ? `${dataCy}-${option?.value}` : undefined}
                    data-cy={`${dataCy}-${option?.value}`}
                  />
                  <span
                    className={cx('checkbox', {
                      'checkbox-error':
                        hasTcError || (props?.hideCheckBoxErrors && props?.errorMessage)
                    })}
                  />
                  <div>
                    <span
                      className={cx(
                        {
                          [cx(styles['drive-form-checkbox__tc-error'])]:
                            hasTcError || (props?.hideCheckBoxErrors && props?.errorMessage)
                        },
                        'tc'
                      )}
                      dangerouslySetInnerHTML={{ __html: option?.label }}
                    />
                  </div>
                </span>
                {/* Hide error messages for Terms and conditions checkbox as it is handled in the label */}
                {option?.value === 'tc' || props?.hideCheckBoxErrors ? null : (
                  <ErrorBag
                    messages={
                      errorMessages?.[option?.value]
                        ? errorMessages?.[option?.value]
                        : Array.isArray(errorMessages)
                        ? errorMessages
                        : null
                    }
                  />
                )}
              </label>
              {option.children ?? null}
            </span>
          )
        })}
    </div>
  )
}

export interface CheckboxProps {
  hideCheckBoxErrors?: boolean
  reset?: boolean
  name?: string
  options?: any[] // Refine if you know the shape of each option
  onChange?: (...args: any[]) => void // Refine signature if you know the event shape
  validation?: (...args: any[]) => boolean // Refine signature if you know the validation logic
  className?: string
  seperatorIndex?: number
  isPage?: boolean
  itemsHeightControlClassNames?: string
  labelClassName?: string
  errorMessage?: object
  checkedOptions?: string[]
  dataCy?: string
  header?: string
  headerClassName?: string
  onFocus?: () => void
}

export default Checkbox
