import React from 'react'
import DisclaimerTooltip from '../../disclaimer-tooltip'
import styles from './cfs-checkbox-disclaimer.module.scss'
import { Color } from '@grille/constants/colors'
import { DisclaimerType } from '@grille/types/disclaimer'
import cx from 'classnames'

type Props = {
  disclaimerType: DisclaimerType
  disclaimerColor?: Color
}
const CFSDisclaimerTooltip = ({ disclaimerType, disclaimerColor }: Props) => {
  return (
    <span aria-hidden='true' className={cx(styles['tooltip-container'])}>
      <div className={cx(styles['tooltip-container__wrapper'])}>
        <DisclaimerTooltip
          disclaimerType={disclaimerType}
          iconProps={{
            color: disclaimerColor ?? Color.black
          }}
        />
      </div>
    </span>
  )
}

export default CFSDisclaimerTooltip
