import React from 'react'
import DisclaimerTooltip from '@grille/components/common/disclaimer-tooltip'
import { Color } from '@grille/constants/colors'
import styles from './finance-enquiry-tooltip.module.scss'

function FinanceEnquiryTootlip() {
  return (
    <div className={styles['tooltip-container']}>
      <DisclaimerTooltip
        disclaimerType='FINANCE_ENQUIRY'
        iconProps={{
          color: Color.white
        }}
      />
    </div>
  )
}

export default FinanceEnquiryTootlip
