import { RULES } from '@grille/utils/validation'
import { Form } from '../'
import { FORM_ERROR_MESSAGE_PHONE, FORM_ERROR_MESSAGE_PHONE_NO_SPECIAL } from '../error-messages'

export const VALUE_AND_SELL_ENQUIRY_FORM: Form = {
  fields: [
    {
      labelClassName: 'placeholder-gray-500 black mb-4 md:mr-4',
      placeholder: 'First Name',
      name: 'firstName',
      type: 'text',
      rules: [
        {
          rule: RULES.isValidName,
          message: 'Please input a valid first name.'
        }
      ]
    },
    {
      labelClassName: 'black mb-4',
      placeholder: 'Last Name',
      name: 'lastName',
      type: 'text',
      rules: [
        {
          rule: RULES.isValidName,
          message: 'Please input a valid last name.'
        }
      ]
    },
    {
      labelClassName: 'black full mb-4',
      placeholder: 'Email Address',
      name: 'enquiryEmail',
      type: 'text',
      rules: [{ rule: RULES.isEmail, minLength: 1, message: 'Please input a valid email.' }]
    },
    {
      labelClassName: 'black mb-4 md:mr-4',
      placeholder: 'Phone',
      name: 'enquiryPhone',
      type: 'text',
      rules: [
        {
          rule: RULES.isValidPhoneWithNoSpecialChars,
          message: FORM_ERROR_MESSAGE_PHONE_NO_SPECIAL
        },
        { rule: RULES.isMobilePhone, message: FORM_ERROR_MESSAGE_PHONE }
      ]
    },
    {
      labelClassName: 'black mb-4',
      placeholder: 'Postcode',
      name: 'enquiryPostcode',
      type: 'text',
      rules: [{ rule: RULES.isPostalCode, args: ['AU'], message: 'Please input a valid postcode.' }]
    },
    {
      className: 'checkboxes my-4 mt-2 flex-row',
      labelClassName: 'w-full lg:w-1/2 mt-4 md:mt-0',
      name: 'checkboxes',
      type: 'checkbox',
      options: [
        {
          name: 'tcCheckBox',
          className: 'foo-bar',
          value: 'tc',
          label:
            'I agree to the <a target="_blank" href="/about-drive/terms-conditions/">Terms & Conditions</a> and <a href="/about-drive/privacy-policy/">Privacy Policy</a>'
        },
        { value: 'newsletter', label: 'Sign up to our newsletter', name: 'newsCheckBox' }
      ],
      rules: {
        tc: [{ rule: RULES.equals, args: ['true'], message: 'Please accept Terms & Conditions.' }]
      }
    }
  ],
  buttons: [
    {
      className: 'w-full text-15px font-semibold',
      color: 'blue',
      text: 'Send Enquiry',
      type: 'submit'
    }
  ]
}

export const SELL_MY_CAR_META_PIXEL_ACCOUNT_ID: number = 566913697051206
export const SELL_MY_CAR_META_PIXEL_TRACKING_EVENTS: string[] = ['PageView', 'CompleteRegistration']
