import { FORM_FIELDS_TYPES } from '@grille/components/enquiry/constants'
import { RULES } from '@grille/utils/validation'
import { Form } from '../'
import { FORM_ERROR_MESSAGE_PHONE, FORM_ERROR_MESSAGE_PHONE_NO_SPECIAL } from '../error-messages'

export const NOVATED_LEASE_FORM: Form = {
  fields: [
    {
      labelClassName: 'firstName',
      placeholder: 'First name',
      label: 'First name',
      name: 'firstName',
      type: FORM_FIELDS_TYPES.ANIMATED_TEXT,
      rules: [{ rule: RULES.isValidName, message: 'Please enter a first name' }]
    },
    {
      labelClassName: 'lastName',
      placeholder: 'Last name',
      label: 'Last name',
      name: 'lastName',
      type: FORM_FIELDS_TYPES.ANIMATED_TEXT,
      rules: [{ rule: RULES.isValidName, message: 'Please enter a valid last name' }]
    },
    {
      labelClassName: 'email',
      placeholder: 'Email',
      label: 'Email',
      name: 'email',
      type: FORM_FIELDS_TYPES.ANIMATED_TEXT,
      rules: [{ rule: RULES.isEmail, minLength: 1, message: 'Please enter a valid email address' }]
    },
    {
      labelClassName: 'phone',
      placeholder: 'Phone number',
      label: 'Phone number',
      name: 'enquiryPhone',
      prefix: '+61',
      type: FORM_FIELDS_TYPES.ANIMATED_PHONE,
      defaultHidden: true,
      rules: [
        {
          rule: RULES.isValidPhoneWithNoSpecialChars,
          message: FORM_ERROR_MESSAGE_PHONE_NO_SPECIAL
        },
        { rule: RULES.isMobilePhone, message: FORM_ERROR_MESSAGE_PHONE }
      ]
    },
    {
      labelClassName: 'postcode',
      placeholder: 'Postcode',
      label: 'Postcode',
      name: 'postcode',
      type: FORM_FIELDS_TYPES.ANIMATED_TEXT,
      defaultHidden: true,
      rules: [{ rule: RULES.isPostalCode, args: ['AU'], message: 'Please enter a valid postcode' }]
    },
    {
      className: 'checkboxes',
      header: 'I’m also interested in:',
      labelClassName: '',
      name: 'checkboxes',
      defaultHidden: true,
      type: FORM_FIELDS_TYPES.CHECKBOX,
      options: [
        { value: 'marketplace', label: `Marketplace news and offers`, name: 'marketplaceCheckBox' }
      ],
      rules: []
    }
  ],
  labels: [
    {
      defaultHidden: false,
      id: 'enquiry-agree-label',
      className: 'agree-label-test',
      label: `By clicking the Submit enquiry button you acknowledge that you have read and agree to the <a href="/">Drive Terms and Conditions </a> and Privacy Policy.`
    }
  ],
  buttons: [
    {
      className: 'blue contact-seller-btn-primary',
      color: 'transparent',
      text: 'Submit enquiry',
      type: 'submit'
    }
  ]
}

export const NOVATED_LEASE_FORM_ID = 'novated-lease-form'
export const NOVATED_LEASE_CONFIRMATION_PAGE_URI = '/confirmation'
