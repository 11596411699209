// Use ES6 to import these from validator
// We then re-export as properties on validation type
import _isAlpha from 'validator/lib/isAlpha'
import _isAscii from 'validator/lib/isAscii'
import _isEmail from 'validator/lib/isEmail'
import _isPostalCode from 'validator/lib/isPostalCode'
import _equals from 'validator/lib/equals'
import _isLength from 'validator/lib/isLength'
import _isNumeric from 'validator/lib/isNumeric'
import { isValidPhoneNumber } from 'libphonenumber-js'
import isEmpty from 'lodash/isEmpty'
import isNumber from 'lodash/isNumber'

export const RULES = {
  equals: 'equals',
  isAscii: 'isAscii',
  isAlpha: 'isAlpha',
  isValidComments: 'isValidComments',
  isEmail: 'isEmail',
  isIn: 'isIn',
  isLength: 'isLength',
  isMobilePhone: 'isMobilePhone',
  isPostalCode: 'isPostalCode',
  isURL: 'isURL',
  isValidName: 'isValidName',
  isValidNumber: 'isValidNumber',
  isVehicleRegistrationNo: 'isVehicleRegistrationNo',
  isValidOdometerReading: 'isValidOdometerReading',
  isValidPhoneWithNoSpecialChars: 'isValidPhoneWithNoSpecialChars'
}

// Validation methods used in enquiry/form.js, common/form/index.js and contact-seller-form.js
const URL_REGEX =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/
export const URL_HTTP_OPTIONAL_REGEX =
  /^(http(s):\/\/.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/
const IP_ADDRESS_REGEX =
  /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/gm

export const isAscii = (str, ...rest) => {
  return _isAscii(str, ...rest)
}

export const isAlpha = (str, ...rest) => {
  return _isAlpha(str, ...rest)
}

export const isEmail = (str, ...rest) => {
  return _isEmail(str, ...rest)
}

export const isPostalCode = (str, locale) => {
  return _isPostalCode(str, locale)
}

export const isLength = (str, ...rest) => {
  return _isLength(str, ...rest)
}

export const equals = (str, locale) => {
  return _equals(str, locale)
}

export const isIn = (str = '', list) => {
  return list && list.includes(str)
}

export const isValidNumber = (num, ...rest) => {
  const parseNo = parseInt(num)
  return isNumber(parseNo) && parseNo > 0
}

/**
 * uknown type is required for zod
 * @param {string | unknown} str
 * @returns
 */
export const isURL = (str = '') => {
  return str.match(URL_REGEX) !== null
}

/**
 * uknown type is required for zod
 * @param {string | unknown} str
 * @returns
 */
export const isValidComments = (str = '') => {
  return !isURL(str) && str.match(IP_ADDRESS_REGEX) == null
}

/**
 * removes comma, then convert to integer
 * fallback to null by default if conversion fail
 * @returns
 */
export const convertNumberWithCommaToInteger = (number, fallback = null) => {
  const parseNo = parseInt(Number(`${number}`.replaceAll(',', '')))

  if (!isNumber(parseNo)) {
    return fallback
  }

  return parseNo
}

/**
 * first strips all commas,
 * then checks following conditions:
 * - is integer
 * - is integer larger than 0
 * - is integer less than 7 digits
 * @returns
 */
export const isValidOdometerReading = (num, max = Infinity) => {
  const parseNo = convertNumberWithCommaToInteger(num)
  return isNumber(parseNo) && parseNo > 0 && parseNo.toString().length <= 6 && parseNo < max
}

/**
 * should only have alphabetical letters
 */
export const isValidName = (str = '') => {
  return str.length >= 1
  // The validation are yet to be finalised by PROD team
  // return str.match(/^[a-zA-Z.\s]+$/)
}

/**
 *
 * @param {String | unknown} value string phone no
 * @returns Returns true if phone no is valid
 */
export const isMobilePhone = (value) => {
  if (isEmpty(value)) {
    return false
  }
  // only allow single space in between
  if (/\d. \s{1,}/.test(value)) {
    return false
  }

  /** Phone no with space is valid */
  const phoneNoString = value.replace('+', '').replace(/\s/g, '')

  // should not allow toll free no's
  if (phoneNoString.substring(0, 4) === '1800') {
    return false
  }

  return isValidPhoneNumber(phoneNoString, 'AU') || false
}

/**
 *
 * @param {String | unknown} value string phone no
 * @returns Returns true if phone no is valid
 */
export const isValidPhoneWithNoSpecialChars = (value) => {
  if (isEmpty(value)) {
    return false
  }

  // does not allow special character except + at the beginning
  return /^(?:\+)?\d+$/.test(value)
}

export const isVehicleRegistrationNo = (registrationId) => {
  if (isEmpty(registrationId)) {
    return false
  }
  // Checks if registration no to have max 7 characters.
  return registrationId && registrationId?.length < 8 && !/[^a-zA-Z0-9]/.test(registrationId)
}

export const isValidYear = (num, min = 0) => {
  const parseNo = parseInt(num)
  return isNumber(parseNo) && parseNo > min
}
