import { RULES } from '@grille/utils/validation'
import { Form } from '../'
import { FORM_ERROR_MESSAGE_PHONE, FORM_ERROR_MESSAGE_PHONE_NO_SPECIAL } from '../error-messages'

export const SELL_MY_CAR_CUSTOMER_DETAILS: Form = {
  fields: [
    {
      labelClassName: 'black',
      placeholder: 'Add first name',
      heading: 'First name',
      name: 'firstName',
      type: 'text',
      showError: true,
      hidden: false,
      rules: [
        {
          rule: RULES.isValidName,
          message: 'Please input a name'
        }
      ]
    },
    {
      labelClassName: 'black',
      placeholder: 'Add last name',
      heading: 'Last name',
      name: 'lastName',
      type: 'text',
      rules: [
        {
          rule: RULES.isValidName,
          message: 'Please input a name'
        }
      ]
    },
    {
      labelClassName: 'black',
      heading: 'Email address',
      placeholder: 'Add email address',
      name: 'email',
      type: 'text',
      rules: [{ rule: RULES.isEmail, minLength: 2, message: 'Please input a valid email address' }]
    },
    {
      labelClassName: 'black',
      placeholder: 'Add phone number',
      heading: 'Phone number',
      name: 'phone',
      defaultHidden: true,
      type: 'text',
      rules: [
        {
          rule: RULES.isValidPhoneWithNoSpecialChars,
          message: FORM_ERROR_MESSAGE_PHONE_NO_SPECIAL
        },
        { rule: RULES.isMobilePhone, message: FORM_ERROR_MESSAGE_PHONE }
      ]
    },
    {
      labelClassName: 'black',
      placeholder: 'Add postcode',
      heading: 'Postcode',
      name: 'postcode',
      type: 'text',
      rules: [
        {
          rule: RULES.isPostalCode,
          args: ['AU'],
          minLength: 2,
          message: 'Please input a valid postcode'
        }
      ]
    },
    {
      labelClassName: 'black',
      placeholder: 'Add registration number',
      heading: 'Registration',
      name: 'regoNumber',
      defaultHidden: true,
      type: 'text',
      rules: [
        {
          rule: RULES.isVehicleRegistrationNo,
          message: 'Please input a valid registration number'
        }
      ]
    },
    {
      labelClassName: 'black',
      placeholder: 'Enter car kilometres',
      heading: 'Odometer (in kilometres)',
      name: 'odometerKm',
      defaultHidden: true,
      type: 'number',
      rules: [
        {
          rule: RULES.isValidOdometerReading,
          message: 'Please input your car’s odometer reading (in km’s). Max 6 characters.'
        }
      ]
    }
  ],
  buttons: [
    {
      className: 'back',
      color: 'blue',
      text: 'Back',
      type: 'button',
      specialType: 'back'
    },
    {
      className: 'submit',
      color: 'blue',
      text: 'Get an offer',
      type: 'submit'
    }
  ]
}
